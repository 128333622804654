import React, { Fragment, useEffect, useState } from "react";
import BaseLayout from "../../Layout/Default";
import Sidebar from "../../Components/Common/Sidebar";
import Comment from "../../Components/Common/Comment";
import { CommentList } from "../../Components/Common/CommentList";
import { BreadCrumb } from "../../Components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import instance from "../../Core/Http/axios";
import Skeleton from "react-loading-skeleton";
import SocialShare from "../../Components/Common/SocialShare";
import { Helmet } from "react-helmet";

const PostDetail = (props: any) => {
    const params = useParams();
    const [postDetail, setPostDetail] = useState<any>({});
    const [otherPostDetail, setOtherPostDetail] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isPostLoaded, setPostLoaded] = React.useState(true);
    const [isOtherPostLoaded, setOtherPostLoaded] = React.useState(true);

    const getOtherRecentPost = async () => {
        setOtherPostLoaded(true);
        try {
            const postDetailFinalEndpoint = '/otherRecentPosts/' + params?.id;
            const response = await instance.get(postDetailFinalEndpoint);
            if (response.status === 200) {
                const { data } = response;
                setOtherPostDetail(data.posts);
            }
        } catch (err) {
            setError('Failed to fetch users');
        } finally {
            setOtherPostLoaded(false);
        }
    }
    const getPostDetails = async () => {
        setPostLoaded(true);
        try {
            const postDetailFinalEndpoint = '/post/' + params?.id;
            const response = await instance.get(postDetailFinalEndpoint);
            if (response.status === 200) {
                const { data } = response;
                setPostDetail(data.posts);
            }
        } catch (err) {
            setError('Failed to fetch users');
        } finally {
            setPostLoaded(false);
        }
    };
    const scrollToTopOnPageLoad = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        scrollToTopOnPageLoad();
        getPostDetails();
        getOtherRecentPost();
    }, [params])
    return (
        <BaseLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{postDetail && postDetail.title}</title>
                <meta name="description" content={postDetail && postDetail.title} />
            </Helmet>
            <section className="post-layout-1-area post-layout-3-area pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {!isPostLoaded ?
                                <BreadCrumb breadCrumb={postDetail} />
                                :
                                <Skeleton height={30} />
                            }
                        </div>
                        <div className="col-lg-8">
                            <div className="post-layout-top-content post-layout-top-content-3">
                                <div className="post-categories d-flex justify-content-between align-content-center">
                                    {/* <div className="categories-share">
                                        <ul>
                                            <li><i className="fas fa-comment"></i>45020</li>
                                            <li><i className="fas fa-fire"></i>45020</li>
                                           
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="post-content">

                                    <h3 className="title">
                                        {!isPostLoaded ?
                                            postDetail && postDetail.title
                                            :
                                            <Skeleton height={30} />
                                        }
                                    </h3>
                                    {!isPostLoaded ?
                                        <div className="post-author">
                                            {/* <div className="author-info"> */}
                                            {/* <div className="thumb">
                                                    <img src="assets/images/author.png" alt="" />
                                                </div> */}
                                            {/* <h5 className="title">Pawan Kushwaha</h5> */}
                                            {/* <ul>
                                                <li>March 26, 2020</li>
                                                <li>Updated 1:58 p.m. ET</li>
                                            </ul> */}
                                            {/* </div> */}
                                            <div className="author-social">
                                                <SocialShare postDetail={postDetail} />
                                            </div>
                                        </div>
                                        :
                                        <Skeleton height={30} />
                                    }

                                </div>
                                {!isPostLoaded ?
                                    <Fragment>
                                        {postDetail && postDetail.image && <img src={postDetail.image} />}
                                        <div className="content" dangerouslySetInnerHTML={{ __html: postDetail && postDetail.description }} >
                                        </div>
                                    </Fragment>
                                    :
                                    <Skeleton height={500} />
                                }


                            </div>
                        </div>
                        <div className="col-lg-4">
                            <Sidebar otherPosts={otherPostDetail} />
                        </div>
                    </div>
                </div>
            </section>
            {/* <Comment /> */}
            {/* <CommentList /> */}
        </BaseLayout>
    )
}
export default PostDetail;