import React, { Fragment } from 'react';
import DOMPurify from 'dompurify';

export const LimitedHTMLContent = (props: any) => {
    // Limit words in the HTML content
    const limitWords = (html: string, wordLimit: number) => {
        // Strip HTML tags to get plain text
        const textContent = html && html.replace(/<\/?[^>]+(>|$)/g, '');

        // Split the text into words
        const words = html && textContent.split(' ');

        // If the word count exceeds the limit, slice it
        if (words && words.length > wordLimit) {
            const limitedText = words && words.slice(0, wordLimit).join(' ') + '...';

            // Return the limited text wrapped in <p> tags (or you can structure as needed)
            return `<p>${limitedText}</p>`;
        }

        // If under the word limit, return the original HTML
        return html;
    }
    const htmlContent = props && props.htmlContent;
    const wordLimit = props.wordLimit;
    const limitedHTML = limitWords(htmlContent, wordLimit);

    // Sanitize the limited HTML using DOMPurify
    const safeHTML = DOMPurify.sanitize(limitedHTML);
    return (
        <Fragment>
            <div dangerouslySetInnerHTML={{ __html: safeHTML }} />
        </Fragment>
    );
}