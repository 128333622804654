import axios from "axios";
if (process.env.NODE_ENV === 'development') {
  console.log('Running in development mode');
  // Use local configurations or API endpoints here
} else if (process.env.NODE_ENV === 'production') {
  console.log('Running in production mode');
  // Use production configurations or API endpoints here
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000, // Request timeout
    headers: {
      'Content-Type': 'application/json',
      'APPKEY':'base64:PwL7SIMYn7JOfh7Q09SZB6Hcza03VW0XQLXWiKy3GdE='
    },
});

export default instance;
