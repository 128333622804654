import Skeleton from "react-loading-skeleton";
import { Fragment } from "react/jsx-runtime";
import Slider from "react-slick";
import PrevArrow from "../../Common/Slick/Prev";
import NextArrow from "../../Common/Slick/Next";
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
};

const HeroPart = (props: any) => {
    return (
        <Fragment>
            {props && props.latestNews && props.latestNews.length > 0 ?
                <section className="hero-area1 mt-2 mb-1">
                    <div className="col-lg-12">
                            {props && props.latestNews && props.latestNews.length>0 ?
                                <div className="header-topbar1 d-none d-lg-block">
                                    <div className="container custom-container">
                                        <div className="row align-items-center">
                                            <div className="col-lg-12">
                                                <div className="topbar-trending">
                                                    <span>Breaking News</span>
                                                    <Slider {...settings} className="trending-slider">
                                                        {props && props.latestNews && props.latestNews.length > 0 &&
                                                            props.latestNews.map((latestNews: any, index: number) => {
                                                                return (
                                                                    <div key={index} className="trending-item">
                                                                        <p>{latestNews.title}</p>
                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                    </Slider>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-3">
                                                <div className="topbar-social d-flex align-items-center">
                                                    <div className="header-menu-search">
                                                        <ul>
                                                            <li><a href="#"><i className="fal fa-user-circle"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="nice-select-item">
                                                        <select>
                                                            <option data-display="English">English</option>
                                                            <option value="1">Bangla</option>
                                                            <option value="2">Hindi</option>
                                                            <option value="3">option</option>
                                                            <option value="4">Potato</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                :
                                <Skeleton height={40} />
                            }
                            
                        </div>

                    {/* <Fragment>
                        {props && props.latestNews && props.latestNews[0] && props.latestNews[0].title &&
                            <div className="bg-image"
                                style={{ backgroundImage: "url(" + props.latestNews[0].image + ")" }}
                            ></div>
                        }
                        <div className="container custom-container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="hero-content">
                                        <div className="hero-meta">
                                            <div className="meta-categories">
                                                <span>{props && props.latestNews && props.latestNews[0] && props.latestNews[0].category && props.latestNews[0].category.name}</span>
                                            </div>
                                            <div className="meta-date">
                                                <span>{props && props.latestNews && props.latestNews[0] && props.latestNews[0].created_at && parseDate(props.latestNews[0].created_at, 'Y')}</span>
                                            </div>
                                        </div>
                                        <Link to={`/${props && props.latestNews && props.latestNews[0] && props.latestNews[0].id}/${props && props.latestNews && props.latestNews[0] && createSlug(props.latestNews[0].title)}`} ><h3 className="title">{props && props.latestNews && props.latestNews[0] && props.latestNews[0].title}</h3></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment> */}
                </section>
                :
                <Skeleton height={500} />
            }
        </Fragment>
    )
}
export default HeroPart;