import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import NotFound from './Modules/Pages/NotFound';
import Home from './Modules/Pages/Home';
import PostDetail from './Modules/Pages/PostDetail';
import CategoryDetail from './Modules/Pages/CategoryDetail';
import AllCategories from './Modules/Pages/AllCategories';
// Lazy load components
// const Home = React.lazy(() => import('./Modules/Pages/Home'));
// const PostDetail = React.lazy(() => import('./Modules/Pages/PostDetail'));
// const CategoryDetail = React.lazy(() => import('./Modules/Pages/CategoryDetail'));
// const AllCategories = React.lazy(() => import('./Modules/Pages/AllCategories'));


function App(){
  return (
    <Router>
      <Suspense>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:id/:slug" element={<PostDetail />} />
          <Route path="/category/:id" element={<CategoryDetail />} />
          <Route path="/categories-list/" element={<AllCategories />} />
           {/* Catch all other routes and render 404 */}
          <Route path="*" element={<NotFound />} />

        </Routes>
      </Suspense>
    </Router>
  );
}
export default function WrappedApp() {
  return (
    <App />
  )
}