import { getYear } from "date-fns";

export const createSlug=(str:string)=>{
    return str && encodeURIComponent(
        str
        .toLowerCase()                // Convert to lowercase (not much effect for Hindi characters)
        .trim()                       // Remove whitespace from both ends
        .replace(/[\s_]+/g, '-')      // Replace spaces and underscores with hyphens
        .replace(/-+/g, '-')          // Replace multiple hyphens with a single hyphen
    );
}
export const parseDate=(date:string,formate:string)=>{
   const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
   const parseDate= new Date(date);
   const currentDate=parseDate.getDate();
   const postMonth=parseDate.getUTCMonth();
   const year=parseDate.getFullYear();
   let monthName = month[postMonth];
   return `${monthName} ${currentDate}, ${year} `;
}
