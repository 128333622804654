import React, { useState } from "react";

const Footer=()=>{
    const [dt]=useState(new Date().getFullYear());
    return(
        <div className="footer-copyright footer-copyright-style-1">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="copyright-item d-block d-md-flex justify-content-center align-items-center">
                        <p>© Copyright {dt}, All Rights Reserved</p>
                        {/* <ul>
                            <li><a href="#">About</a></li>
                            <li><a href="#">Advertise</a></li>
                            <li><a href="#">Privacy & Policy</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Footer;