import React, { useEffect, useState } from "react";
import { createSlug } from "../../Core/Util/functionUtil";

export const SocialShare = (props) => {
    const [facebookShareUrl,setFacebookShareUrl] =useState("");
    const [instragramShareLink,setInstragramShareLink] =useState("");
    const faceBookShare=()=>{
        let url="/";
        let quote="";
        if(props && props.postDetail){
            url=process.env.REACT_APP_BASE_URL+`/${props.postDetail.id}/${createSlug(props.postDetail.title)}`;
            quote=props.postDetail.title;
        }   
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(quote)}`;
        setFacebookShareUrl(facebookShareUrl);
    }
    const InstagramShare=()=>{
        let url="/";
        if(props && props.postDetail){
            url=process.env.REACT_APP_BASE_URL+`/${props.postDetail.id}/${createSlug(props.postDetail.title)}`;
        }   
        const instragramShareLink = `https://www.instagram.com/direct/new/?text=${encodeURIComponent(url)}`;
        setInstragramShareLink(instragramShareLink);
    }
    useEffect(()=>{
        faceBookShare();
        InstagramShare();
    },[])
    return (
        <ul>
            <li><a href={facebookShareUrl} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
            <li><a href={instragramShareLink} target="_blank"><i className="fab fa-instagram"></i></a></li>
        </ul>
    )
}
export default SocialShare;