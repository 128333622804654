import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Pagination = (props: any) => {
    const [dummyArray,setDummyArray] = useState(Array.from({ length: props.pagination.last_page }, (_, i) => i + 1));
    const [paginationObj,setPagination]=useState(props.pagination)
    useEffect(()=>{
        setDummyArray(Array.from({ length: props.pagination.last_page }, (_, i) => i + 1));
        setPagination(props.pagination)
    },[props])
    return (
        <div className="pagination-item">
            { paginationObj && paginationObj.last_page>1 &&
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        {dummyArray && dummyArray.length > 0 &&
                            dummyArray.map((dummy: any, index: number) => {
                                return (<li key={index} className={`page-item ${dummy===paginationObj.current_page?'active':''}`}><Link className="page-link" to={`?page=${dummy}`}>{dummy}</Link></li>)
                            })
                        }
                    </ul>
                </nav>
             } 
        </div>
    )
}
