import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { createSlug } from "../../Core/Util/functionUtil";
export const Sidebar = (props: any) => {
    const [dummyArray] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    return (
        <div className="all-post-sidebar-style-2 ">
            <div className="most-view-box">
                <div className="section-title">
                    {props && props.otherPosts && props.otherPosts.length > 0 ?
                        <h3 className="title">Other News</h3>
                        :
                        <Skeleton height={30} />
                    }
                </div>
                <div className="trending-sidebar-slider">
                    {props && props.otherPosts && props.otherPosts.length > 0 ?
                        props.otherPosts.map((otherPost: any, index: number) => {
                            return(<div key={index} className="post_gallery_items">
                                <div className="post-gallery-style-2 most-view-style-2">
                                    <div className="post-gallery-thumb">
                                        <img src={otherPost.image} alt={otherPost.title} />
                                        <span>{index+1}</span>
                                    </div>
                                    <div className="post-gallery-content">
                                        <h5 className="title"><Link to={`/${otherPost.id}/${createSlug(otherPost.title)}`}>{otherPost.title}</Link></h5>
                                        <div className="meta-post-2-style">
                                            <div className="meta-post-categores">
                                                <Link to={`${process.env.REACT_APP_BASE_URL}/category/${otherPost.category.id}`}>{otherPost.category.name}</Link>
                                            </div>
                                            {/* <div className="meta-post-date">
                                                <span>April 26, 2020</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })
                        :
                        dummyArray.map((dummy: any, index: number) => {
                            return (<div key={index} className="post_gallery_items">
                                <div className="post-gallery-style-1 most-view-style-2">
                                    <div className="post-gallery-thumb1">
                                        <Skeleton height={60} />
                                    </div>
                                    <div className="post-gallery-content">
                                        <h5 className="title"><Skeleton height={30} /></h5>
                                        {/* <div className="meta-post-2-style">
                                        <Skeleton height={60} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}
export default Sidebar;