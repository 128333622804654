import Slider from "react-slick"
import PrevArrow from "../../Common/Slick/Prev";
import NextArrow from "../../Common/Slick/Next";
import Skeleton from "react-loading-skeleton";
import { Fragment } from "react/jsx-runtime";
import { createSlug, parseDate } from "../../../Core/Util/functionUtil";
import { LimitedHTMLContent } from "../../../Core/Common/LimitedHTMLContent";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instance from "../../../Core/Http/axios";
import { API_URL } from "../../../Core/Constant/Apiurl";

var trendingImageSlideSetting = {
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
}
var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    className: '.trending-slider-1',
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 500,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                arrows: false,
            }
        }
    ]

};


export const Trending = (props: any) => {
    const [dummyArray] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [trendingPosts, setTrendingPosts] = useState([]);
    const [financePosts, setFinancePosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingFinance, setLoadingFinance] = useState(true);

    const getTrendingPosts = async () => {
        setLoading(true);
        try {
            const response = await instance.get(API_URL.TRENDING+'?limit=6');
            if (response.status === 200) {
                const { data } = response;
                setTrendingPosts(data.posts);
            }
        } catch (err) {
            console.log('Failed to fetch trending posts');
        } finally {
            setLoading(false);
        }
    }
    const getEntertainmentPosts = async () => {
        setLoadingFinance(true);
        try {
            const response = await instance.get(API_URL.ENTERTAINMENT+'?limit=3');
            if (response.status === 200) {
                const { data } = response;
                setFinancePosts(data.posts.data);
            }
        } catch (err) {
            console.log('Failed to fetch finance posts');
        } finally {
            setLoadingFinance(false);
        }
    }
    useEffect(() => {
        getTrendingPosts();
        getEntertainmentPosts();
    }, []);
    return (
        <section className="trending-3-area">
            <div className="container custom-container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="trending-news-3 mt-10">
                            <div className="section-title section-title-3">
                                {props && props.trendingNews && props.trendingNews.length > 0 ? <h3 className="title">Trending </h3> : <Skeleton height={30} />}
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="trending-news-3-item">
                                        <div className="trending-news-thumb">
                                            {props && props.trendingNews && props.trendingNews.length > 0 && props.trendingNews[0].image ?
                                                <Fragment>
                                                    <img src={props.trendingNews[0].image} alt={props.trendingNews[0].title} />
                                                    <div className="icon">
                                                        <a href="#"><i className="fas fa-bolt"></i></a>
                                                    </div>
                                                </Fragment>
                                                :
                                                <Skeleton height={200} />
                                            }
                                        </div>
                                        <div className="trending-news-content">
                                            {props && props.trendingNews && props.trendingNews.length > 0 && props.trendingNews[0].category ?
                                                <div className="post-meta">
                                                    <div className="meta-categories">
                                                        <Link to={`/category/${props.trendingNews[0].category && props.trendingNews[0].category.id}`}>{props.trendingNews[0].category && props.trendingNews[0].category.name}</Link>
                                                    </div>
                                                    <div className="meta-date">
                                                        <span>
                                                            {props && props.trendingNews && props.trendingNews.length > 0 && props.trendingNews[0].created_at ?
                                                                parseDate(props.trendingNews[0].created_at, 'Y')
                                                                :
                                                                <Skeleton height={30} />
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="share-link">
                                                        <ul>
                                                            {/* <li><a href="#"><i className="fal fa-bookmark"></i></a></li> */}
                                                            {/* <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent()}&quote=${encodeURIComponent(quote)}`} target="_blank"><i className="fas fa-share"></i></a></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                :
                                                <Skeleton height={100} />
                                            }
                                            <h3 className="title"><Link to={`/${props && props.trendingNews && props.trendingNews.length > 0 && props.trendingNews[0].id}/${createSlug(props && props.trendingNews && props.trendingNews.length > 0 && props.trendingNews[0].title)}`}>{props && props.trendingNews && props.trendingNews.length > 0 && props.trendingNews[0].title ? props.trendingNews[0].title : <Skeleton height={30} />}
                                            </Link></h3>
                                            <LimitedHTMLContent htmlContent={props && props.trendingNews && props.trendingNews.length > 0 && props.trendingNews[0].description} wordLimit={20} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="post_gallery_items">
                                        {props && props.trendingNews && props.trendingNews.length > 0 ?
                                            props.trendingNews.map((trenging: any, index: number) => {
                                                if(index > 0){
                                                return (<div key={index} className="gallery_item trending-sidebar">
                                                    <div className="gallery_item_thumb">
                                                        <img src={trenging.image} alt="gallery" />
                                                    </div>
                                                    <div className="gallery_item_content">
                                                        <div className="post-meta">
                                                            <div className="meta-categories">
                                                                <Link to={`/category/${trenging.category.id}`}>{trenging.category && trenging.category.name}</Link>
                                                            </div>
                                                            <div className="meta-date">
                                                                <span>{parseDate(trenging.created_at, 'Y')}</span>
                                                            </div>
                                                        </div>
                                                        <h4 className="title"><Link to={`/${trenging.id}/${createSlug(trenging.title)}`}>{trenging.title}</Link></h4>
                                                    </div>
                                                </div>)}
                                            })
                                            :
                                            dummyArray.map((dummy: any, index: number) => {
                                                return (<div key={index} className="gallery_item gallery_item_3">
                                                    <div className="gallery_item_thumb">
                                                        <Skeleton height={100} />
                                                    </div>
                                                    <div className="gallery_item_content">
                                                        <div className="meta-date">
                                                            <Skeleton height={30} width={170} />
                                                        </div>
                                                        <h4 className="title"><Skeleton height={150} width={170} /></h4>
                                                    </div>
                                                </div>)
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="trending-slider-item mt-40">
                            {/* <div className="row trending-slider-3"> */}
                            <Slider {...settings} className="trending-slider-3 ">
                                {
                                    !loading ?
                                    trendingPosts && trendingPosts.length>0 && trendingPosts.map((technoloyPost: any, index: number) => {
                                            return (<div key={index} className="ml-20">
                                                <div className="international-item international-3-item">
                                                    <div className="meta-category">
                                                        <span>{technoloyPost.category && technoloyPost.category.name}</span>
                                                    </div>
                                                    <h5 className="title"><Link to={`/${technoloyPost.id}/${createSlug(technoloyPost.title)}`}>{technoloyPost.title}</Link></h5>
                                                    <div className="text-thumb d-flex align-items-center">
                                                        <LimitedHTMLContent htmlContent={technoloyPost.description} wordLimit={30} />
                                                        <div className="thumb">
                                                            <img src={technoloyPost.image} alt={technoloyPost.title} />
                                                        </div>
                                                    </div>
                                                    {/* <ul>
                                                        <li><a href="#"><i className="far fa-eye"></i> 53,346</a></li>
                                                        <li><a href="#"><i className="far fa-heart"></i> 53,346</a></li>
                                                    </ul> */}
                                                </div>
                                            </div>)
                                        })
                                        :
                                        dummyArray.map((dummy: any, index: number) => {
                                            return (
                                                <div key={index} className="ml-20">
                                                    <div className="international-item international-3-item">
                                                        <div className="meta-category">
                                                            <Skeleton height={20} />
                                                        </div>
                                                        <h5 className="title"> <Skeleton height={10} /></h5>
                                                        <div className="text-thumb d-flex align-items-center">
                                                            <Skeleton height={10} />
                                                            <div className="thumb">
                                                                <Skeleton height={50} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                            </Slider>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="finance-3-style mt-10">
                            <div className="section-title section-title-3">
                                <h3 className="title">{!loadingFinance ? 'Entertainment' : <Skeleton height={30} />}</h3>
                            </div>
                            {!loadingFinance ?
                                financePosts && financePosts.length>0 && financePosts.map((financePost: any, index: number) => {
                                    return (<div key={index} className="finance-item finance-item-3">
                                        <div className="finance-thumb">
                                            <img src={financePost.image} alt={financePost.title} />
                                            <div className="finance-date">
                                                <span>{parseDate(financePost.created_at,'Y')}</span>
                                            </div>
                                        </div>
                                        <div className="finance-content">
                                            <h3 className="title"><Link to={`/${financePost.id}/${createSlug(financePost.title)}`}>{financePost.title}</Link></h3>
                                            <LimitedHTMLContent htmlContent={ financePost.description}  wordLimit={10}/>
                                            {/* <ul>
                                                <li><a href="#"><i className="far fa-eye"></i> 53,346</a></li>
                                                <li><a href="#"><i className="far fa-heart"></i> 53,346</a></li>
                                                <li><a href="#"><i className="fas fa-share"></i> 53,346</a></li>
                                            </ul> */}
                                        </div>
                                    </div>)
                                })
                                :
                                <Skeleton height={500} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}