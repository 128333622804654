import { Link } from "react-router-dom";

export const BreadCrumb = (props:any) => {
    return (
        <div className="about-author-content">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to={`/category/${props &&  props.breadCrumb && props.breadCrumb.category && props.breadCrumb.category.id}`}>{props &&  props.breadCrumb && props.breadCrumb.category && props.breadCrumb.category.name}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Health</li>
                </ol>
            </nav>
        </div>
    )
}