import React from "react";
import BaseLayout from "../../Layout/Default";

const AllCategories: React.FC = () => {
    return (
        <BaseLayout>
            <section className="about-item-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-tab-btn mt-40">
                                <div className="archive-btn">
                                    <ul>
                                        <li><span>All Categories</span></li>
                                    </ul>
                                </div>
                                <div className="about-post-items">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="trending-image-post feature-item mt-30">
                                                <img src="assets/images/feature/feature-1.png" alt="" />
                                                    <div className="trending-image-content">
                                                        <div className="post-meta">
                                                            <div className="meta-categories">
                                                                <a href="#">TECHNOLOGY</a>
                                                            </div>
                                                            <div className="meta-date">
                                                                <span>March 26, 2020</span>
                                                            </div>
                                                        </div>
                                                        <h3 className="title"><a href="#">Japans virus success has from puzzled the running out?</a></h3>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="trending-image-post feature-item mt-30">
                                                <img src="assets/images/feature/feature-2.png" alt="" />
                                                    <div className="trending-image-content">
                                                        <div className="post-meta">
                                                            <div className="meta-categories">
                                                                <a href="#">TECHNOLOGY</a>
                                                            </div>
                                                            <div className="meta-date">
                                                                <span>March 26, 2020</span>
                                                            </div>
                                                        </div>
                                                        <h3 className="title"><a href="#">Japans virus success has from puzzled the running out?</a></h3>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="trending-image-post feature-item mt-30">
                                                <img src="assets/images/feature/feature-2.png" alt="" />
                                                    <div className="trending-image-content">
                                                        <div className="post-meta">
                                                            <div className="meta-categories">
                                                                <a href="#">TECHNOLOGY</a>
                                                            </div>
                                                            <div className="meta-date">
                                                                <span>March 26, 2020</span>
                                                            </div>
                                                        </div>
                                                        <h3 className="title"><a href="#">Japans virus success has from puzzled the running out?</a></h3>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </BaseLayout>
    )
}
export default AllCategories;