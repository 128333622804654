import React, { Fragment, useEffect, useState } from "react";
import BaseLayout from "../../Layout/Default";
import LatestNews from "../../Components/Partials/LatestNews";
import SportsNews from "../../Components/Partials/SportsNews";
import instance from "../../Core/Http/axios";
import HeroPart from "../../Components/Partials/Home/HeroPart";
import SubPart from "../../Components/Partials/Home/SubPart";
import { Trending } from "../../Components/Partials/Home/Trending";
import { PostPlay } from "../../Components/Partials/Home/PostPlay";
import { AllPosts } from "../../Components/Partials/Home/AllPosts";
import { API_URL } from "../../Core/Constant/Apiurl";
import {Helmet} from "react-helmet";


const Home: React.FC = () => {
    const [latestNews, setLatestNews] = useState([]);
    const [trendingNews, setTrendingNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchLatestNews = async () => {
        setLoading(true);
        try {
            const response = await instance.get(API_URL.LATEST_NEWS + '?limit=5');
            if (response.status === 200) {
                const { data } = response;
                setLatestNews(data.posts);
            }
        } catch (err) {
            console.log('Failed to fetch latest posts');
        } finally {
            setLoading(false);
        }
    };
    const fetchTrendingNews = async () => {
        try {
            const response = await instance.get(API_URL.TRENDING + '?limit=6');
            if (response.status === 200) {
                const { data } = response;
                setTrendingNews(data.posts);
            }
        } catch (err) {
            console.log('Failed to fetch trending posts');
        }
    };
    useEffect(() => {
        fetchLatestNews();
        fetchTrendingNews();
    }, [])
    return (
        <div>
            <BaseLayout >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Get Latest News in Hindi, Breaking News in Hindi  and english from Jharkhand, India and world at publicpatrika.com.</title>
                    <meta name="description" content="Get Latest News in Hindi and english from Jharkhand, India and world at publicpatrika.com." />
                </Helmet>
                <HeroPart latestNews={latestNews} />
                {/* <SubPart latestNews={latestNews} /> Other than first latest news */}
                <Trending trendingNews={trendingNews} /> {/* trending and entertainement news */}
                <AllPosts />
                {/* <PostPlay /> */}
            </BaseLayout>
        </div>
    )
}
export default Home;