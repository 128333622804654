export const API_URL={
    TRENDING: '/trending-posts',
    INTERNATIONAL:'/category/11',
    SCIENCE:'/category/11',
    MENUS:'/menus',
    LATEST_NEWS:'/latest-posts',
    FINANCE:'/category/13',
    SPORTS:'/category/6',
    ENTERTAINMENT:'/category/7',
    CINEMA:'/category/8',
    HEALTH:'/category/9'

}