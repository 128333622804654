import { Link } from "react-router-dom";
import BaseLayout from "../../Layout/Default";
const  NotFound = () => {
    return (
        <div>
            <BaseLayout >
                <section className="error-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="error-thumb text-center">
                                    <img src="assets/images/404.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="error-content text-center">
                                    <h3 className="title">Page not found</h3>
                                    <p>Sorry the page you were looking for cannot be found. Try searching for the best match or browse the links below:</p>
                                    <ul>
                                        <li><Link className="main-btn" to="/">GO TO HOME</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </BaseLayout>
        </div>
    )
}
export default NotFound;