import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import NextArrow from "../Common/Slick/Next";
import PrevArrow from "../Common/Slick/Prev";
import { Icons } from "../../Core/Constant/Icons";
import instance from "../../Core/Http/axios";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link, useParams } from "react-router-dom";
import { API_URL } from "../../Core/Constant/Apiurl";
import { SessionKey } from "../../Core/Constant/SessionKey";
import { json } from "stream/consumers";

function Header() {
    const [pages, setPages] = useState<any[]>([]);
    const [latestNews, setLatestNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMenus, setLoadingMenus] = useState(true);
    const params = useParams();
    const [error, setError] = useState<string | null>(null);
    const [dt, setDt] = React.useState(new Date().toLocaleString());

    const fetchMenus = async () => {
        setLoadingMenus(true);
       

        try {
            const response = await instance.get(API_URL.MENUS);
            if (response.status === 200) {
                const { data } = response;
                if(!sessionStorage.getItem(SessionKey.MENUS)){
                    sessionStorage.setItem(SessionKey.MENUS,JSON.stringify(data.pages));
                }
                console.log(data.posts)

                setPages(data.pages);
            }
        } catch (err) {
            setError('Failed to fetch menus');
        } finally {
            setLoadingMenus(false);
        }
    };
    const fetchLatestNews = async () => {
        setLoading(true);
        try {
            const response = await instance.get(API_URL.LATEST_NEWS);
            if (response.status === 200) {
                const { data } = response;
                setLatestNews(data.posts);
            }
        } catch (err) {
            setError('Failed to fetch latest news');
        } finally {
            setLoading(false);
        }
    };
    const updateTimer = () => {
        let secTimer = setInterval(() => {
            setDt(new Date().toLocaleString())
        }, 1000)
        return () => clearInterval(secTimer);
    }
    React.useEffect(() => {
        
        if(sessionStorage.getItem(SessionKey.MENUS)){
            setLoadingMenus(false);
            const menus:any=sessionStorage.getItem(SessionKey.MENUS)
            setPages(JSON.parse(menus));
        }else{
            
            fetchMenus();
        }
        fetchLatestNews();
        updateTimer();
    }, []);
    useEffect(()=>{

    },[pages])
    if (!pages) return null;
    return (
        <Fragment>{loading}
            <header className="header-area header-style-2 header-style-3">
                <div className="container custom-container">
                    <div className="row no-gutters">
                        <div className="col-lg-12 mt-20">
                            <div className="menu-logo">
                                <div className="header-logo">
                                    <Link to="/"><img src={Icons.Logo} alt="" /></Link>
                                </div>
                                <div className="header-menubar">
                                    <div className="container custom-container">
                                        {!loadingMenus ?
                                            <div className="menubar-bg">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-12 col-sm-3 col-3">
                                                        <div className="header-menu header-menu-style-2">
                                                            <div className="stellarnav">
                                                                <ul>
                                                                    {pages && pages.length > 0 &&
                                                                        pages.map((page: any, index: number) => {
                                                                            return (
                                                                                (!page.id) ?
                                                                                    <li key={index} ><Link className={params && !params?.id ? 'active' : ''} to={page.url}>{page.name}</Link></li>
                                                                                    :
                                                                                    <li key={index} ><Link className={params?.id == page.id ? 'active' : ''} to={`${process.env.REACT_APP_BASE_URL}/category/${page.id}`}>{page.name}</Link></li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-3 col-sm-9 col-9">
                                        <div className="header-menu-rightbar">
                                            <div className="header-search">
                                                <form action="#">
                                                    <div className="input-box">
                                                        <button type="button"><i className="far fa-search"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> */}
                                                </div>
                                            </div>
                                            :
                                            <Skeleton height={60} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </header>
        </Fragment >
    )
}
export default Header;