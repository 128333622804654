import React, { Fragment, useState } from "react";
import Header from "../Components/Elements/Header";
import Footer from "../Components/Elements/Footer";

export default function BaseLayout(props:any) {
    return (
        <Fragment>
            <Header  />
            {props.children}
            <Footer />
        </Fragment>
    );
}