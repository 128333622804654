import { useEffect, useState } from "react";
import Slider from "react-slick"
import instance from "../../../Core/Http/axios";
import { createSlug, parseDate } from "../../../Core/Util/functionUtil";
import { Link } from "react-router-dom";
import { LimitedHTMLContent } from "../../../Core/Common/LimitedHTMLContent";
import { API_URL } from "../../../Core/Constant/Apiurl";

export const AllPosts = () => {
    const [sportsPosts, setSportsPosts] = useState<any[]>([]);
    const [healthPosts, setHealthPosts] = useState<any[]>([]);
    const getSportsPosts = async () => {
        try {
            const response = await instance.get(API_URL.SPORTS+'?limit=4');
            if (response.status === 200) {
                const { data } = response;
                setSportsPosts(data.posts.data);
            }
        } catch (err) {
            console.log('Failed to fetch international posts');
        }
    }
    const getHealthPosts = async () => {
        try {
            const response = await instance.get(API_URL.HEALTH+'?limit=4');
            if (response.status === 200) {
                const { data } = response;
                setHealthPosts(data.posts.data);
            }
        } catch (err) {
            console.log('Failed to fetch science posts');
        }
    }
    // const handleSeeMore = async () => {
    //     try {
    //         const response = await instance.get('/latest-posts?limit=10');
    //         if (response.status === 200) {
    //             const { data } = response;
    //             setsportsPosts((prevState)=>[
    //                 ...prevState,
    //                 ...data.posts
    //             ]);
    //         }
    //     } catch (err) {
    //         console.log('Failed to fetch trending posts');
    //     }
    // }
    useEffect(()=>{

    },[sportsPosts])
    useEffect(() => {
        getHealthPosts();
        getSportsPosts();
    }, []);

    return (
        <section className="all-post-3-area1 pb-5">
            <div className="container custom-container">
                <div className="row">
                    {sportsPosts && sportsPosts.length > 0 &&
                        <div className="col-lg-8">
                            <div className="international-news-area">
                                <div className="section-title section-title-3">
                                    <h3 className="title">Sports News</h3>
                                </div>

                                <div className="international-news-items">
                                    {sportsPosts.map((internationalPost: any, index: number) => {
                                        return (<div key={index} className="international-news-item">
                                            <div className="international-news-thumb">
                                                <img src={internationalPost.image} alt={internationalPost.title} />
                                            </div>
                                            <div className="international-news-content">
                                                <div className="international-meta">

                                                    <div className="meta-date">
                                                        <span>{parseDate(internationalPost.created_at, 'Y')}</span>
                                                    </div>
                                                    <div className="share-link">
                                                        <ul>
                                                            {/* <li><a href="#"><i className="fal fa-bookmark"></i></a></li> */}
                                                            {/* <li><a href="#"><i className="fas fa-share"></i></a></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <h3 className="title"><Link to={`/${internationalPost.id}/${createSlug(internationalPost.title)}`}>{internationalPost.title}</Link></h3>
                                                <LimitedHTMLContent htmlContent={internationalPost.description} wordLimit={20} />
                                                <Link to={`/${internationalPost.id}/${createSlug(internationalPost.title)}`}>LEARN MORE</Link>
                                            </div>
                                        </div>)
                                    })}
                                    <div className="international-btn text-center">
                                        <Link className="main-btn main-btn-2" to={`/category/${sportsPosts &&sportsPosts.length>0&& sportsPosts[0].category.id}`}>See More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    {healthPosts && healthPosts.length > 0 &&
                        <div className="col-lg-4">
                            <div className="science-news-area">
                                <div className="section-title section-title-3">
                                    <h3 className="title">Health</h3>
                                </div>

                                {healthPosts.map((sciencePost: any, index: number) => {
                                    return (<div key={index} className="science-news-item">
                                        <h3 className="title"><Link to={`/${sciencePost.id}/${createSlug(sciencePost.title)}`}>{sciencePost.title}</Link></h3>
                                        <div className="post-text-thumb">
                                            <div className="thumb">
                                                <img src={sciencePost.image} alt={sciencePost.title} />
                                            </div>
                                            <div className="text">
                                                <LimitedHTMLContent htmlContent={sciencePost.description} wordLimit={10} />
                                            </div>
                                        </div>
                                        <div className="post-user">
                                            <a href="#" onClick={(e) => e.preventDefault()}><i className="fal fa-bookmark"></i></a>
                                            <span>By <span>Pawan Kushwaha</span></span>
                                            <p>{parseDate(sciencePost.created_at, 'Y')}</p>
                                        </div>
                                    </div>)
                                })}
                                <div className="international-btn text-center">
                                <Link className="main-btn main-btn-2" to={`/category/${healthPosts &&healthPosts.length>0&& healthPosts[0].category.id}`}>See More</Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section >

    )
}