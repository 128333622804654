import React, { useEffect, useState } from "react";
import BaseLayout from "../../Layout/Default";
import { BreadCrumb } from "../../Components/Common/Breadcrumb";
import { Link, useParams, useSearchParams } from "react-router-dom";
import instance from "../../Core/Http/axios";
import Skeleton from "react-loading-skeleton";
import { LimitedHTMLContent } from "../../Core/Common/LimitedHTMLContent";
import { Pagination } from "../../Components/Common/Pagination";
import { createSlug } from "../../Core/Util/functionUtil";
import { Helmet } from "react-helmet";

const CategoryDetail = () => {
    const params = useParams();
    const [searchParams] = useSearchParams()
    const [dummyArray] = useState([1, 2, 3, 4, 6, 7, 8, 9]);
    const [error, setError] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [postsByCategory, setPostsByCategory] = useState<any[]>([]);
    const [pagination, setPagination] = useState({});
    const page = searchParams.get('page');

    const getPostByCategory = async () => {
        setIsLoaded(true);
        try {
            let postByCategoryFinalEndpoint = '/category/' + params?.id;
            if (page) {
                postByCategoryFinalEndpoint = postByCategoryFinalEndpoint + '?page=' + page
            }
            const response = await instance.get(postByCategoryFinalEndpoint);
            if (response.status === 200) {
                const { data } = response;
                setPostsByCategory(data.posts.data);
                setPagination({
                    current_page: data.posts.current_page,
                    from: data.posts.from,
                    last_page: data.posts.last_page
                });
            }
        } catch (err) {
            setError('Failed to fetch users');
        } finally {
            setIsLoaded(false);
        }
    }
    const scrollToTopOnPageLoad = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        scrollToTopOnPageLoad();
        getPostByCategory();
    }, [params])
    return (
        <BaseLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Get Latest News in Hindi, Breaking News in Hindi  and english from Jharkhand, India and world at publicpatrika.com.</title>
                <meta name="description" content="Get Latest News in Hindi and english from Jharkhand, India and world at publicpatrika.com." />
            </Helmet>
            <section className="about-item-area pt-10">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-12">
                            <BreadCrumb />
                        </div> */}
                        <div className="col-lg-12 mt-10">
                            <div className="about-tab-btn mt-8">
                                <div className="archive-btn mb-10">
                                    {!isLoaded ?
                                        <span>Category: {postsByCategory && postsByCategory[0] && postsByCategory[0].category.name}</span>
                                        :
                                        <Skeleton height={10} />
                                    }
                                </div>
                                <div className="about-post-items category">
                                    <div className="row">
                                        {!isLoaded ?
                                            postsByCategory && postsByCategory.length > 0 ?
                                                postsByCategory.map((post: any, index: number) => {
                                                    return (<div key={index} className="col-lg-4 col-md-6">
                                                        <div className="trending-news-item mb-30">
                                                            <div className="trending-news-thumb">
                                                                <img src={post.image} alt="trending" />
                                                                <div className="icon">
                                                                    <Link to={`/${post.id}/${createSlug(post.title)}`}><i className="fas fa-bolt"></i></Link>
                                                                </div>
                                                            </div>
                                                            <div className="trending-news-content">
                                                                <h3 className="title"><Link to={`/${post.id}/${createSlug(post.title)}`}>{post.title}</Link></h3>
                                                                <LimitedHTMLContent html={post.description} wordLimit={400} />
                                                            </div>
                                                        </div>
                                                    </div>)
                                                })
                                                :
                                                <div> No record found</div>
                                            :
                                            dummyArray.map((dummy: any, index: number) => {
                                                return (<div key={index} className="col-lg-4 col-md-6">
                                                    <div className="trending-news-item mb-30">
                                                        <div className="trending-news-thumb">
                                                            <Skeleton height={100} />
                                                        </div>
                                                        <div className="trending-news-content">
                                                            <Skeleton height={30} />
                                                        </div>
                                                    </div>
                                                </div>)
                                            })
                                        }
                                        <div className="col-lg-12">
                                            <Pagination pagination={pagination} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </BaseLayout >
    )
}
export default CategoryDetail;